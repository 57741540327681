import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Dashboard = lazy(() => import("pages/Dashboard"));

const Bookings = lazy(() => import("pages/Bookings"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));

const Employees = lazy(() => import("pages/Employees"));
const AddEmployee = lazy(() => import("pages/AddEmployee"));
const EmployeeDetails = lazy(() => import("pages/EmployeeDetails"));

const Franchises = lazy(() => import("pages/Franchises"));
const AddFranchise = lazy(() => import("pages/AddFranchise"));
const FranchiseDetails = lazy(() => import("pages/FranchiseDetails"));


export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/profile", element: <Profile /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/booking", element: <Bookings type={"market"} /> },

  { path: "/ongoing-bookings", element: <Bookings type={"planned"} /> },
  { path: "/past-bookings", element: <Bookings type="completed" /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },

  { path: "/franchises", element: <Franchises /> },
  { path: "/add-franchise", element: <AddFranchise /> },
  { path: "/update-franchise/:id", element: <AddFranchise /> },
  { path: "/franchise-details/:id", element: <FranchiseDetails /> },

  { path: "/employees", element: <Employees /> },
  { path: "/add-employee", element: <AddEmployee /> },
  { path: "/update-employee/:id", element: <AddEmployee /> },
  { path: "/employee-details/:id", element: <EmployeeDetails /> },
];
