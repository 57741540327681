import { config } from "config";
import { SetFranchisesPayload, FranchiseState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: FranchiseState = {
  count: 0,
  franchises: [],
  franchise: null,
  loading: true,
  franchiseOptions: [],
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const franchiseSlice = createSlice({
  name: "franchise",
  initialState,
  reducers: {
    clear: (state) => {
      state.franchiseOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addFranchise: (state, action) => {
      state.franchises.unshift(action.payload);
    },
    updateFranchise: (state, action) => {
      const { id, franchise } = action.payload;
      state.franchises.every(({ _id }, i) => {
        if (id === _id) {
          state.franchises[i] = franchise;
          return false;
        }
        return true;
      });
    },
    deleteFranchiseById: (state, action) => {
      const id = action.payload;
      state.franchises.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.franchises.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setFranchise: (state, action) => {
      state.franchise = action.payload;
    },
    setFranchises: (state, action: PayloadAction<SetFranchisesPayload>) => {
      const { count, franchises } = action.payload;
      let options: SelectOption[] = [];
      franchises.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });
      state.count = count;
      state.franchises = franchises;
      state.franchiseOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const franchiseReducer = franchiseSlice.reducer;

export const franchiseActions = franchiseSlice.actions;
export default franchiseReducer;
