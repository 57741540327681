import { Box, Stack, styled, Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { ReactNode } from "react";
import { Navigation } from "./components/navigation/Navigation";
import { ToolbarElements } from "./components/toolbar-elements/ToolbarElements";
import Logo from "assets/logo.png";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import useEffectOnce from "hooks/useEffectOnce";
import AuthService from "services/auth.service";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: "none",
  borderBottom: "1px solid #e0e0e0",
}));

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
}));

interface Props {
  children: ReactNode;
}

export function SidebarLayout({ children }: Props) {
  const location = useLocation();
  const { pathname } = location;
  const user = useAppSelector((state) => state.auth.user);

  useEffectOnce(() => {
    if (!user) return;
    AuthService.splash();
  });

  if (
    !user ||
    pathname === "/" ||
    pathname === "/password-otp" ||
    pathname === "/reset-password" ||
    pathname === "/forgot-password"
  )
    return <>{children}</>;

  return (
    <Box>
      <AppBar position="sticky" color="transparent" sx={{ background: "white" }}>
        <Toolbar sx={{ minHeight: "72px !important" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            flex={1}
          >
            <img
              src={Logo}
              alt="logo"
              loading="lazy"
              style={{ width: "100%", maxWidth: "140px" }}
            />
            <Navigation />
            <ToolbarElements />
          </Stack>
        </Toolbar>
      </AppBar>
      <Main>{children}</Main>
    </Box>
  );
}
