import { lazy, useMemo } from "react";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AccountTreeOutlined,
  TimeToLeaveOutlined,
  SupportAgent,
  AddBoxOutlined,
  HubOutlined,
  BadgeOutlined,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { NavigationItemType } from "./components/navigation-item/types";
// import BookingsTabs from "pages/Bookings/BookingsTabs";
const BookingsTabs = lazy(() => import("pages/Bookings/BookingsTabs"));

export const useNavigationItems = (): NavigationItemType[] => {
  return useMemo(
    () => [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        path: "/booking",
        label: "Booking",
        icon: (props: any) => <SupportAgent {...props} />,
        items: [
          {
            label: "Upcoming",
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: "On Going",
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: "Past",
            icon: (props: any) => <FactCheckOutlined {...props} />,
            element: <BookingsTabs />,
          },
        ],
      },
      {
        path: "/franchises",
        label: "Franchises",
        icon: (props: any) => <HubOutlined {...props} />,
      },
      {
        path: "/employees",
        label: "Employees",
        icon: (props: any) => <BadgeOutlined {...props} />,
      },
    ],
    []
  );
};
