import List from "@mui/material/List";
import { NavigationItem } from "./components/navigation-item/NavigationItem";
import { useNavigationItems } from "./NavigationItems";

export function Navigation() {
  const navigationItems = useNavigationItems();

  const navigationItemsList = navigationItems.map((item) => {
    return <NavigationItem key={Object.values(item).toString()} item={item} />;
  });

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 1100,
          padding: 2,
          gap: 2,
          display: "flex",
          flexDirection: "row",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {navigationItemsList}
      </List>
    </>
  );
}
